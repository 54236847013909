import { redirect } from '@/common/lib/router';
import {
  useOnSessionError,
  useOnSessionUpdate,
  useSession,
} from '@/feat/auth/context';
import Router from 'next/router';
import { ReactElement, ReactNode } from 'react';
import LoadingScreen from '@/common/components/loading-screen';

export function AuthGuard({
  children,
  loadingMessage = <LoadingScreen />,
  disableRedirect,
}: {
  children: ReactNode;
  loadingMessage?: ReactElement | null;
  disableRedirect?: boolean;
}) {
  const { data: sessionData } = useSession();

  useOnSessionUpdate((data) => {
    if (Router.pathname === '/login') return;
    if (data.isOutletLoggedIn) return;
    if (!disableRedirect) {
      Router.push({
        pathname: '/login',
        query: { continue: Router.asPath },
      });
    }
  });
  useOnSessionError(() => {
    if (!disableRedirect) {
      redirect('/logout');
    }
  });

  if (sessionData?.isOutletLoggedIn) {
    return <>{children}</>;
  }

  return loadingMessage;
}
